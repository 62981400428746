<template>
  <FinalForm
      ref="form"
      :submit="handleSubmit"
      :initial-values="initialValues"
      @change.self="handleFormChange"
      class="h-full"
  >
    <template v-slot="props">
      <form @submit="props.handleSubmit" class="h-full flex flex-col overflow-hidden">
        <div class="flex flex-col">
          <div class="flex items-center justify-center border border-active-150 bg-active-50 text-active-800 p-5 mb-10">
            <ExclamationCircleIcon class="w-4 h-4 mr-3"/>
            <span class="font-frank font-medium text-sm">after saving you won’t be able to edit or delete it</span>
          </div>

          <div class="assignees-field flex flex-col mb-10">
            <div class="font-inter font-semibold text-black text-xs mb-2">committed by *</div>
            <SelectInput
                name="persons"
                :validate="composeValidators(notEmpty, maxLength(5))"
                :options="personsOptions"
                :multiple="true"
                :disabled="!hasSreqPermission('TLMoB')"
            />
            <div v-if="hasSreqPermission('TLMoB')" class="form-hint">Maximum 5 people</div>
          </div>
        </div>

        <div class="flex flex-col flex-grow overflow-hidden">
          <div class="font-frank font-semibold text-sm text-black mb-6">
            time-in/time-out log*
          </div>

          <div class="flex-grow overflow-auto">
            <div class="new-log-line" v-for="(item, index) of props.values.logItems" :key="index">
              <div class="">
                <DateTimeField
                    :name="`logItems.${index}.inDate`"
                    label="time-in"
                    :max-date="(new Date()).toISOString()"
                    :validate="[
                      required,
                      maxDateTime(new Date().toISOString(), formatDateTime)
                    ]"
                    date-placeholder="05/20/2022"
                    time-placeholder="12:34 pm"
                />
              </div>
              <div class="flex items-end px-3">
                <hr class="border-graphite-500 w-4 mb-5">
              </div>
              <div class="">
                <DateTimeField
                    :key="`out-${index}-${formValues.logItems[index].inDate}`"
                    :name="`logItems.${index}.outDate`"
                    label="time-out"
                    :min-date="formValues.logItems[index].inDate"
                    :max-date="(new Date()).toISOString()"
                    :validate="[
                      minDateTime(formValues.logItems[index].inDate, formatDateTime),
                      maxDateTime(new Date().toISOString(), formatDateTime)
                    ]"
                    date-placeholder="05/20/2022"
                    time-placeholder="12:34 pm"
                />
              </div>
              <div class="ml-8">
                <DurationField
                    :name="`logItems.${index}.duration`"
                    label="duration"
                    @change="({value}) => handleDurationChange(index, value)"
                />
              </div>
              <div
                  class="flex flex-col items-center justify-center"
                  :class="props.values.logItems.length >= 10 && index === 0 ? 'px-10' : 'px-8'"
              >
                <plus-circle-icon
                    v-if="props.values.logItems.length < 10"
                    @click="addLogItem"
                    class="w-4 h-4 text-active-500 cursor-pointer"
                    :class="index === 0 ? 'mb-6' : 'mb-2'"
                />
                <minus-circle-icon
                    v-if="index !== 0"
                    @click="removeLogItem(index)"
                    class="w-4 h-4 text-graphite-800 cursor-pointer"
                    :class="props.values.logItems.length >= 10 && 'mt-6'"
                />
              </div>
            </div>
            <div v-if="props.values.logItems.length >= 10" class="form-hint">
              You can’t add more than 10 lines into one note
            </div>
          </div>
        </div>

        <div class="modal-footer" id="modal-footer">
          <div>
            <button type="button" class="btn-primary btn-transparent" @click="close">
              cancel
            </button>
          </div>
          <div class="flex">
            <button type="submit" class="btn-primary btn-solid">
              save
            </button>
          </div>
        </div>

        <loader :loading="loading" backdrop/>
      </form>
    </template>
  </FinalForm>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import ExclamationCircleIcon from "@/components/ui/icons/ExclamationCircleIcon";
import {FinalForm} from 'vue-final-form';
import SelectInput from "@/components/form/SelectInput";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import PlusCircleIcon from "@/components/ui/icons/PlusCircleIcon";
import MinusCircleIcon from "@/components/ui/icons/MinusCircleIcon";
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import DateTimeField from "@/components/form/DateTimeField"
import DurationField from "@/components/form/DurationField";
import SreqMixin from "@/mixins/SreqMixin";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";

export default {
  components: {
    DurationField,
    DateTimeField,
    Loader,
    MinusCircleIcon,
    PlusCircleIcon,
    SelectInput,
    ExclamationCircleIcon,
    FinalForm
  },

  mixins: [ModalNavigation, ValidatorMixin, ModalFooterMixin, NotifyMixin, SreqMixin, CommunityTimezoneMixin],

  data() {
    return {
      loading: false,
      personsOptions: [],
      initialValues: {
        persons: [],
        logItems: [
          { inDate: new Date().toISOString(), outDate: null, duration: null },
        ],
      },
      formValues: null,
    };
  },

  methods: {
    handleFormChange(state) {
      this.formValues = state.values;
    },

    handleDurationChange(index, value) {
      if (!value) return;

      const {logItems} = this.formValues;

      if (!logItems[index].inDate) return;

      const outDate = this.parseDateTime(logItems[index].inDate).add(value, 'minutes').toISOString();

      this.$refs.form.finalForm.change(`logItems.${index}.outDate`, outDate);
    },

    handleSubmit(values) {
      this.loading = true;

      this.$sreqDataProvider.create('timeInOutLogs', {
        data: {
          serviceRequestId: this.$route.params.requestId,
          logs: values.logItems.map(item => ({
            inDate: item.inDate,
            outDate: item.outDate,
            userIds: values.persons,
          })),
        }
      }).then(() => {
        this.notifySuccess('Successfully saved in/out time');
        this.close();
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },

    addLogItem() {
      this.$refs.form.finalForm.change('logItems', [...this.formValues.logItems, { inDate: new Date().toISOString(), outDate: null }]);
    },

    removeLogItem(index) {
      this.$refs.form.finalForm.change('logItems', [...this.formValues.logItems.filter((item, ind) => ind !== index)]);
    },
  },

  created() {
    this.formValues = this.initialValues;
  },

  mounted() {
    this.$sreqDataProvider.getList('assignees').then(assignees => {
      this.personsOptions = assignees.sort((a, b) => b.currentUser - a.currentUser)
          .map(item => ({...item, key: item.id, value: `${item.firstName} ${item.lastName}`.toLowerCase()}));

      if (!this.hasSreqPermission('TLMoB')) {
        const currentUserId = assignees.find(({currentUser}) => currentUser)?.id;
        if (currentUserId) {
          this.$refs.form.finalForm.change('persons', [currentUserId]);
        }
      }
    });
  }
}
</script>

<style scoped>
.assignees-field:deep(.form-col) {
  @apply mx-0;
}

.new-log-line {
  @apply grid mb-8;
  grid-template-columns: 1fr auto 1fr 1fr auto;
}
.new-log-line:deep(label) {
  @apply text-xs
}
</style>
